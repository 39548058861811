import React from 'react';

const LogoIcon = () => (
  <div>
    <img
      src={require('../assets/logo.png')}
      alt="logo"
      width="140"
      height="60"
    />
  </div>
);

export default LogoIcon;
